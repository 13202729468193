import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/climate-environmental-justice/climate-environmental-justice-hero.webp'
import accomplishment1 from '../../images/issues/climate-environmental-justice/climate-environmental-justice-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/climate-environmental-justice/climate-environmental-justice-accomplishment-2.webp'
import accomplishment3 from '../../images/issues/climate-environmental-justice/climate-environmental-justice-accomplishment-3.webp'
import accomplishment4 from '../../images/issues/climate-environmental-justice/climate-environmental-justice-accomplishment-4.webp'
import accomplishment5 from '../../images/issues/climate-environmental-justice/climate-environmental-justice-accomplishment-5.webp'

const ClimateEnvironmentalJusticePage = () => (
  <Layout>
    <Seo title="Issues - Climate &amp; Environmental Justice" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
            className="w-full max-h-96 object-cover object-pos-0-42" // TODO: figure out aspect ratio -- trying to set 2:1
            src={hero}
            alt=""
          />
        </div>
        <div className="max-w-5xl mx-auto">
          <h1 className="pt-10 font-bold text-6xl">Climate &amp; Environmental Justice</h1>
          <p className="pt-5 text-2xl leading-relaxed">
            Imagine a revitalized city where all people have access to clean air and water, fresh, healthy food, safe homes, and accessible and attractive public sites. The city has ample affordable housing and transportation that everyone can use fairly to access well-paying jobs, schools, and community spaces. This type of transformation leads to an overwhelming sense of security and belonging as pollution, poverty, and environmental injustice become nothing more than a sorrowful tale of the past.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            The time has come to change the mistakes and bad decisions of the past and transition to a more sustainable, environmentally-friendly community, and tackle the climate crisis head-on.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            All residents and visitors should have the right to use ecologically official green spaces and parks around the city. Trees help moderate urban heat, air pollution, carbon emissions, and offer emotional respite and a sense of beauty and calm. Urban forests and gardens encourage everyone in the community to work together, grow healthy food for themselves and their families, and take pride in their neighborhood. Increasing green spaces, planting more trees, and developing public gardens remain some of our fervent goals.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Besides beautifying our neighborhood with greening, we will expand economic and social opportunities for entrepreneurship, education, social engagement, and environmental stewardship.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Climate change slowdown or reversal requires fast action when it comes to adopting green initiatives. The city of Providence is particularly vulnerable to flooding near the coast and higher summertime temperatures. We propose a carbon-neutral plan that would result in 100% decarbonization and reliance on renewable energy. This is the type of leadership that the city and our country need to enter a healthier and more equitable future.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Lower-income, working-class, immigrant, and racial minority communities, especially around the Port of Providence, experience higher levels of environmental hazards such as urban heat, pollution, flooding, and other serious effects of the changing climate. We must combat environmental racism and improve access to green initiatives across all of our neighborhoods and communities. Strong action against climate change must welcome input and involvement from a diverse range of voices when it comes to creating new policies and putting plans into action.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Passed legislation to study and develop a city-wide residential compost program</h3>
              <p>Lead sponsor on passed legislation to a study and to develop a near and long-term city-wide residential compost program in collaboration with Zero Waste Providence, the Office of Sustainability, the Department of Public Works, and the ESTF. Read more <a href="https://www.ecori.org/government/2021/1/4/y8fom3fcjww2qhoxhjazkuqvo9lta5" target="_blank">here</a>, and read the report <a href="https://www.providenceri.gov/wp-content/uploads/2021/11/CompostReport_102021_FINAL.pdf" target="_blank">here</a>.</p>
            </div>
            <div>
              <a href="https://www.providenceri.gov/wp-content/uploads/2021/11/CompostReport_102021_FINAL.pdf" target="_blank"><img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              /></a>
            </div>
            <div>
              <h3 className="font-bold text-xl">Passed legislation to create an Environmentally Preferable Purchasing (EPP) policy in Providence</h3>
              <p>Lead sponsor on passed legislation to create an Environmentally Preferable Purchasing (EPP) policy in the City of Providence to reduce greenhouse-gas emissions, polyfluoroalkyl substances (PFAS), bisphenols, orthophthalates, flame retardants, and more. Read more <a href="https://www.ecori.org/government/2021/1/4/y8fom3fcjww2qhoxhjazkuqvo9lta5" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Called for Environmental Compliance in the Port of Providence</h3>
              <p>Lead co-sponsor in calling for Environmental Compliance in the Port of Providence. Read more <a href="https://council.providenceri.gov/2021/03/18/providence-city-councilors-call-for-environmental-compliance-in-the-port-of-providence/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Organized the community and pushed National Grid to plant about 70 replacement trees in Fox Point</h3>
              <p>Organized the community and pushed National Grid to plant about 70 replacement trees in Fox Point in response to them cutting down three London plane trees in the neighborhood. Read more <a href="https://www.ecori.org/smart-growth/2021/5/5/providence-makes-room-for-restitution-trees" target="_blank">here</a>, <a href="https://www.nationalgridus.com/News/2021/04/Planting-Positivity-with-Power4Tomorrow/" target="_blank">here</a> and <a href="https://council.providenceri.gov/2020/06/12/statement-from-councilman-john-goncalves-regarding-national-grids-plan-to-remove-trees-along-the-south-main-street-corridor/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Supported the development of multi-modal transportation</h3>
              <p>Remained a firm and avid supporter of bike infrastructure and the South Water Street Trail.  Read more <a href="https://council.providenceri.gov/2021/10/12/councilman-john-goncalves-and-local-residents-express-support-for-the-south-water-street-trail-roadway-project/" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment5}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl mt-5">Strong leaders have the opportunity to boost Providence into the global spotlight as a representation of effective climate change initiatives and environmental justice. Working with green organizations and prominent activists, John suggests a Climate Justice agenda for the city. This comprehensive plan outlines an enthusiastic roadmap for changing policies and processes to help create a better future for our children. The goals focus on sustainable and alternative energy, healthy communities that foster connectivity and inclusion, and quality jobs in new sectors.</p>

          <h3 className="font-bold text-3xl mt-5">A Climate Justice for Providence</h3>
          <p className="text-xl">Climate changes are undeniably entwined with economic and racial justice in the world today. Cities like Providence must take action to reduce the threat of these changes, reduce the related economic issues, eradicate the race-based wage gap, and dismantle systemic racism in all offices and organizations.</p>

          <h3 className="font-bold text-3xl mt-5">Clean Water and Air Protects Public Health</h3>
          <p className="text-xl">Pollution remains a concern in all modern cities, and Providence is no stranger to its effects on public health. People who live in South Providence experience pollution from the port while those who live in and around Fox Point experience constant air pollution from nearby highways. The reduction or elimination of these and other environmental problems will not go long away until we take strident measures to provide safe access to clean water and air for every resident. Public health depends on adopting environmentally friendly practices and managing climate change.</p>

          <h3 className="font-bold text-3xl mt-5">The Battle for Environmental Justice</h3>
          <p className="text-xl">Lower-income, working-class, immigrant and racial minority communities experience higher levels of environmental hazards such as urban heat, pollution, flooding, and other serious effects of the changing climate. We must combat environmental racism and improve access to green initiatives across all of our neighborhoods and communities. Strong action against climate change must welcome input and involvement from a diverse range of voices when it comes to creating new policies and putting plans into action.</p>

          <h3 className="font-bold text-3xl mt-5">Create Greener Buildings and Sustainable Transportation</h3>
          <p className="text-xl">The overall carbon footprint expands when buildings and transport systems lack eco-friendly features. We can increase comfort, efficiency, and public health by installing high-quality heaters, solar panels, and stormwater collection and use systems. Not only will the quality of life increase, but utility costs will go down. The same types of improvements to our transportation systems provide similar benefits. Multimodal transport reduces the need for gas-powered cars and trucks, which in turn reduces traffic and air pollution.</p>

          <h3 className="font-bold text-3xl mt-5">Push Toward a Carbon Neutrality</h3>
          <p className="text-xl">Climate change slowdown or reversal requires fast action when it comes to adopting green initiatives. The city of Providence is particularly vulnerable to flooding near the coast and higher summertime temperatures. We propose a carbon-neutral plan that would make for a full 100% reliance on renewable energy by the year 2040. This is the type of leadership that the city and our country need to enter a healthier and more equitable future.</p>

          <h3 className="font-bold text-3xl mt-5">Boost Green Jobs and Employment Opportunities</h3>
          <p className="text-xl">The Climate Justice includes strong plans to improve employment opportunities in renewable energy and other eco-friendly industries. We will push to make these changes so more workers receive fair wages, healthcare and other benefits, and a safe workplace environment. This initiative will include public schools, technical schools, work training centers, and organized labor unions. Our goal of economic justice for all requires firm dedication to sustainable living and opportunity.</p>

          <h3 className="font-bold text-3xl mt-5">Increase Open Green Space in Providence</h3>
          <p className="text-xl">All residents and visitors should have the right to use ecologically official green spaces and parks around the city. Trees help moderate urban heat, air pollution, carbon emissions, and offer emotional respite and a sense of beauty and calm. Urban forests and gardens encourage everyone in the community to work together, grow healthy food for themselves and their families, and take pride in their neighborhood. Increasing green spaces, planting more trees, and developing public gardens remain one of our fervent goals.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default ClimateEnvironmentalJusticePage
